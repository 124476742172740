.App {
}
.nav-link{
    padding-right: 10px;
}
.active{
   font-weight: bold; 
}
.LoaderButton{
  margin-right: 5px;
}